<template>
	<div class="user-tool-item">
		<div class="user-tool-menu">
			<el-table
			    :data="tools"
				:show-header="true"
				class="user-tool-table">
			    <el-table-column
			      prop="name"
			      label="工具名稱"
				  align="center">
			    </el-table-column>
				
			    <el-table-column prop="create_time" label="激活時間" align="center">
				</el-table-column>
				
			    <el-table-column
			      prop="expire_time"
			      label="到期時間"
				  align="center">
			    </el-table-column>
				
			   <el-table-column label="是否vip" align="center">
			        <template #default="scope">
			   		 <el-tag v-if="scope.row.is_vip ===false" type="success" >不是</el-tag>
			   		<el-tag v-if="scope.row.is_vip ===true" type="danger" >是</el-tag>
			   		
			   
			        </template>
			    </el-table-column>
			</el-table>
		</div>
		
		<!-- <div class="user-tool-pagination">
		    <el-pagination
		        background
		        layout="total, prev, pager, next"
		        :current-page="pageIndex"
		        :page-size="pageSize"
		        :total="totalSize"
		        @current-change="handlePageChange"
		    ></el-pagination>
		</div> -->
	</div>
</template>

<script>
	import {GetUserTools} from "../api/index";
	export default {
		
		data(){
			return{
				tools: [
					{
				        name: '工具一',
				        time1: '2022-02-02',
						time2:"2022-02-03",
						desc:"适用于什么没事没事"
				    },
					{
					    name: '工具一',
					    time1: '2022-02-02',
						time2:"2022-02-03",
						desc:"适用于什么没事没事"
					},
					{
					    name: '工具一',
					    time1: '2022-02-02',
						time2:"2022-02-03",
						desc:"适用于什么没事没事"
					},
					{
					    name: '工具一',
					    time1: '2022-02-02',
						time2:"2022-02-03",
						desc:"适用于什么没事没事"
					}
				],
				
				pageIndex:1,
				pageSize:10,
				totalSize:100,
			}
		},
		
		created() {
		    // 获取数据
			this.get_user_tool()
		},
		
		methods:{
			get_user_tool(){
				GetUserTools().then(res=>{
					console.log(res)
					this.tools = res.data.data
				})
			},
			handlePageChange(val) {
			    this.pageIndex = val;
			},
			
		}
		
		
	}
</script>

<style>
	
	.user-tool-item{
		display: flex;
		align-items:center;
		flex-direction: column;
		width: 100%;
	}
	
	.user-tool-menu{
		width: 100%;
		margin-top: 0px;
	}
	
	.user-tool-table{
		width: 100%;
		margin-top: 0px;
	}
	
	.user-tool-pagination{
		width: 100%;
		margin-top: 30px;
		
		display:flex;
		align-items: center;
		justify-content: center;
	}
	
	
</style>