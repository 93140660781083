<template>
	<div class="invite-list">
		
		<div class="invite-main">
			<div class="invite-top">
				邀請返利說明
			</div>
			<div class="invite-desc">
				<div v-for="(item,index) in list" :key="index">
					<div class="invite-desc-item">
						<span style="margin-left: 20px;color:#000;font-weight: bold;font-size: 16px;padding-top: 10px;padding-bottom: 10px;">
						    {{item}}
						</span>
					</div>
				</div>
				<div class="invite-desc-item">
					<span style="margin-left: 20px;color:#000;font-weight: bold;font-size: 16px;padding-top: 10px;padding-bottom: 10px;">
					    您的邀請連接：<span style="color: red;margin-left: 20px;">{{invite_url}}</span> <el-button link type="success" @click="handleEdit(invite_url)">
						  複製連接
					  </el-button>
					</span>
				</div>
			</div>
		</div>
		
		<div class="invite-t1">
			
			<div class="invite-t1-0">
				
				<div class="invite-t1-01">
					<el-image :src="image.user"></el-image>
				</div>
				
				<div v-if="is_mobile" class="invite-t1-00">
					<div style="font-size: 12px;">
						已註冊用戶
					</div>
					<div style="font-size: 14px;font-weight: bold;">
						{{invite_num}}
					</div>
				</div>
				<div v-else class="invite-t1-00">
					<div style="font-size: 20px;">
						已註冊用戶
					</div>
					<div style="font-size: 40px;font-weight: bold;">
						{{invite_num}}
					</div>
				</div>
				
			</div>
			
			<div class="invite-t1-1">
				<div class="invite-t1-02">
					<el-image :src="image.rate"></el-image>
				</div>
				<div v-if="is_mobile" class="invite-t1-00">
					<div style="font-size: 12px;">
					    邀請佣金比例
					</div>
					<div style="font-size: 14px;font-weight: bold;">
						{{rate}}%
					</div>
				</div>
				<div v-else class="invite-t1-00">
					<div style="font-size: 20px;">
					    邀請佣金比例
					</div>
					<div style="font-size: 40px;font-weight: bold;">
						{{rate}}%
					</div>
				</div>
			</div>
			
			<!--
			<div class="invite-t1-2">
				<div class="invite-t1-03">
					<el-image :src="image.coin"></el-image>
				</div>
				<div v-if="is_mobile" class="invite-t1-00">
					<div style="font-size: 12px;">
					    确认中佣金
					</div>
					<div style="font-size: 14px;font-weight: bold;">
						{{profit}}
					</div>
				</div>
				<div v-else class="invite-t1-00">
					<div style="font-size: 20px;">
					    确认中佣金
					</div>
					<div style="font-size: 40px;font-weight: bold;">
						{{profit}}
					</div>
				</div>
			</div>
			-->
			
		</div>
		
		
		<div class="invite-t2">
			
			<div class="invite-top" style="background-color: #2bb885;">
				<el-image :src="image.share" style="width: 30px;height: 30px;"></el-image>
				<div style="margin-left: 10px;">邀請管理</div>
			</div>
			<el-tabs class="invite-tabs" v-model="index" @tab-click="onSelectIndex">
			    <el-tab-pane label="邀請用戶" name="0" style="color: #2bb885;">
					<InviteCodeView></InviteCodeView>
				</el-tab-pane>
			    <el-tab-pane label="佣金紀錄" name="1">
					<InviteProfitView></InviteProfitView>
				</el-tab-pane>
			</el-tabs>
		</div>
		
		<div class="invite-t3"></div>
		
	</div>
</template>

<script>
	
	import {GetInviteNum} from "../api/index";
	import InviteCodeView from "./InviteCode.vue";
	import InviteProfitView from "./InviteProfit.vue";
	
	export default {
		
		components:{
			InviteCodeView,
			InviteProfitView
		},
		
		data(){
			return{
				rate:'',
				invite_url:'',
				image:{
					share:require("../assets/img/iconfont/share.png"),
					user:require("../assets/img/iconfont/user.png"),
					rate:require("../assets/img/iconfont/rate.png"),
					coin:require("../assets/img/iconfont/coin.png"),
					
				},
				
				list:[
					"Ta充值時您可獲得TA充值金額的 20% 返利獎勵",
					"如果您有資源或人脈需要更高的 返利比例 請聯繫我們.",
					"返利金额满 100U 即可提现，未满 100U 申请提现将会被拒绝",
					"提現要求：必須是在套餐有效期內提現，如果套餐過期則不允許提現",
					"提現方式：USDT-TRC20",
					"提現處理時間為每月2次，15號處理一次，30號處理一次"
				],
				
				people:0,
				rate:10,
				profit:0,
				
				index:"0",
				invite_num:0,
				is_mobile:false
			}
		},
		
		created() {
			this.is_mobile = this.isMobile();
			var url =  localStorage.getItem("invite_url")
			var code = localStorage.getItem("invitation_code")
			this.invite_url = url +"?invite=" + code
			this.rate = localStorage.getItem("rate")
			var withdraw_price = localStorage.getItem("withdraw_price")
			this.list[0]= "Ta充值時您可獲得TA充值金額的 " + this.rate +"% 返利獎勵"
			this.list[2]= "返利金額滿 "+ withdraw_price + "U 即可提現，未滿 " + withdraw_price + "U 申請提現將會被拒絕"
			this.get_invite_num()
		},
		
		methods:{
			get_invite_num(){
				GetInviteNum().then(res=>{
					this.invite_num = res.data
				})
			},
			handleEdit(data) {
			   this.$copyText(data).then(()=>{
				   this.$message({
					   type:"success",
					   message:"複製成功"
				   });
			   });
			},
			
			isMobile() {
			    let userAgentInfo = navigator.userAgent;
			    let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
			    let getArr = Agents.filter(i => userAgentInfo.includes(i));
			    return getArr.length ? true : false;
			},
			
			onSelectIndex(tab){
				this.index = tab.index;
			}
			
			
			
		}
		
	}
</script>

<style>
@import url("./Invite.css");
</style>