<template>
	<div class="invite-item">
		
		<!-- <div style="cursor: pointer;font-size: 16px;line-height: 20px;color: #2bb885;margin-top: 10px;" @click="onNewCode">
			+新建邀请码
		</div> -->
		
		<div class="invite-menu">
			<el-table
			    :data="invites"
				:show-header="true"
				class="invite-table">
			    <el-table-column
			      fixed
			      prop="id"
			      label="id"
				  align="left">
			    </el-table-column>
				
			    <el-table-column prop="phone" label="帳號" align="center">
				</el-table-column>
			
			    <el-table-column
			      prop="create_time"
			      label="創建日期"
				  align="center">
			    </el-table-column>
				
			   <!-- <el-table-column
			      label="操作"
				  align="center"
				  fixed="right">
				  
				  <template #default="scope">
				      <el-button link type="success" @click="handleEdit(scope.$index, scope.row)">
						  复制链接
					  </el-button>
				  </template>
				  
			    </el-table-column> -->
			</el-table>
		</div>
		
		<div class="invite-pagination">
		    <el-pagination
		        background
		        layout="total, prev, pager, next"
		        :current-page="form.p"
		        :page-size="form.num_of_page"
		        :total="form.total"
		        @current-change="handlePageChange"
		    ></el-pagination>
		</div>
	</div>
</template>

<script>
	import {GetInviteUsers} from "../api/index";
	export default {
		
		data(){
			return{
				invites: [
					{
				        id: '2324324',
				        code: 'xxxxxxxx',
				        num: '1000',
				        date: '2022-02-02',
						url:"https://www.baidu.com/"
				    }
				],
				form:{
					p:1,
					num_of_page:5,
					total:5,
				}
			}
		},
		created() {
			this.get_invite_user()
		},
		
		methods:{
			get_invite_user(){
				GetInviteUsers(this.form).then(res=>{
					this.invites = res.data.results
					this.form.total = res.data.total
				})
			},
			handlePageChange(val) {
			    this.form.p = val;
				this.get_invite_user()
			},
			// 编辑操作
			handleEdit(index,data) {
			   this.$copyText(data.url).then(()=>{
				   this.$message({
					   type:"success",
					   message:"复制成功"
				   });
			   });
			},
			
			// 新建邀请码
			onNewCode(){
				
			}
			
		}
		
		
	}
</script>

<style>
	
	.invite-item{
		display: flex;
		align-items:center;
		flex-direction: column;
		width: 100%;
	}
	
	.invite-menu{
		width: 100%;
		margin-top: 20px;
	}
	
	.invite-table{
		width: 100%;
		margin-top: 0px;
	}
	
	.invite-pagination{
		width: 100%;
		margin-top: 30px;
		
		display:flex;
		align-items: center;
		justify-content: center;
	}
	
	
</style>