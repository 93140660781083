<template>
	<div>
		<div class="profile-header1">
			
			<div class="profile-logo" @click="onHome"> 
				<div class="profile-logo1">
					
				</div>
			</div>
			
			<div class="profile-tag">
				<el-image :src="image.tag" style="margin-left: 30px;width: 30px;height: 30px;"></el-image>
				<div style="margin-left: 10px;">{{title}}</div>
			</div>
			
			
		</div>
		
		<div class="content1">
			
			<div class="profile-left">
				
				<div class="profile-left-list">
					<div v-for="(item,index) in list" :key="index">
					    <div v-if="index==tag" class="profile-left-item1" @click="onSelectItem(index)">
							<el-image :src="item.prefix2" 
							style="width: 24px;height: 24px;margin-left: 20px;"></el-image>
							<div class="profile-left-title1">{{item.title}}</div>
						</div>
						<div v-else class="profile-left-item0" @click="onSelectItem(index)">
							<el-image :src="item.prefix1" 
							style="width: 24px;height: 24px;margin-left: 20px;"></el-image>
							<div class="profile-left-title0">{{item.title}}</div>
						</div>
					</div>
				</div>
				<div style="margin-top: 100px;">
					<div style="margin-left: 10px;color: #9fa4a6;">注意事項:</div>
					<div style="margin-left: 20px;margin-right: 10px; color: #9fa4a6;font-size: 8px;">
																					該網站所有操作邏輯都是提前設定好的，如有設置不當造成帳號封禁，騷擾他人等不承擔法律責任並享有最終解釋權</div>
					<div style="margin-left: 20px;margin-right: 10px; color: #9fa4a6;font-size: 8px;">僅提供給自動化測試人員，需要大量重複操作的用戶，如有灰黑產業等違反法律條款的使用人員，將有權停止使用使用並封禁</div>
					
				</div>
				<div class="profile-left-bottom">
					<div style="color: #9fa4a6;margin-left: 20px;font-weight: bold;font-size: 16px;">TK Internet</div>
					<div style="color: #9fa4a6;margin-left: 20px;font-size: 12px;">© 2018-2023 All Rights Reserved</div>
					<div style="color: #9fa4a6;margin-left: 20px;font-size: 12px;margin-top: 10px;">
						<span>Made with by </span>
						<span style="color: #000;font-size: bold;">ttk</span>
					</div>
				</div>
			</div>
			
			<div class="profile-main1">
				<GuideView v-if="0==tag"></GuideView>
				<ShopView v-if="2==tag"></ShopView>
				<OrderView v-if="3==tag"></OrderView>
				<InviteView v-if="4==tag"></InviteView>
				<UserView v-if="5==tag"></UserView>
			</div>
		
			
		</div>		
	</div>
</template>

<script>
	import {UserInfo} from "../api/index";
	import GuideView from "./Guide.vue";
	import ShopView from "./Shop.vue";
	import OrderView from "./Order.vue";
	import InviteView from "./Invite.vue";
	import UserView from "./User.vue";
	
	export default {
		
		components:{
		    GuideView,
			ShopView,
			OrderView,
			InviteView,
			UserView,
			
		},
		
		data(){
			return{
				tag:0,
				title:"",
				is_vip:true,
				image:{
					tag:require("../assets/img/iconfont/tag.png"),
					logo:require("../assets/img/logo.png"),
					logo2:require("../assets/img/logo2.png"),
				},
				
				list:[
					{
						title:"使用教程",
						value:0,
						prefix1:require("../assets/img/tab/t0-0.png"),
						prefix2:require("../assets/img/tab/t0-1.png"),
					},
					{
						title:"最新公告",
						value:1,
						prefix1:require("../assets/img/tab/t1-0.png"),
						prefix2:require("../assets/img/tab/t1-1.png"),
					},
					{
						title:"購買訂閱",
						value:2,
						prefix1:require("../assets/img/tab/t2-0.png"),
						prefix2:require("../assets/img/tab/t2-1.png"),
					},
					{
						title:"我的訂單",
						value:3,
						prefix1:require("../assets/img/tab/t3-0.png"),
						prefix2:require("../assets/img/tab/t3-1.png"),
					},
					{
						title:"我的邀請",
						value:4,
						prefix1:require("../assets/img/tab/t4-0.png"),
						prefix2:require("../assets/img/tab/t4-1.png"),
					},
					{
						title:"用戶中心",
						value:5,
						prefix1:require("../assets/img/tab/t5-0.png"),
						prefix2:require("../assets/img/tab/t5-1.png"),
					},
				
				]
				
			}
		},
		
		created(){
			
			if(this.$router.currentRoute.value.query && this.$router.currentRoute.value.query.index){
				this.tag = parseInt("" + this.$router.currentRoute.value.query.index);
			}
			// console.log("tag: " + this.tag);
			this.title = this.list[this.tag].title;
			this.user_info()
			
		},
		
		methods:{
			user_info(){
				UserInfo().then(res => {
					console.log(res)
					this.is_vip = res.data.is_vip
					localStorage.setItem("amount", res.data.profit.amount);
					localStorage.setItem("is_invite", res.data.is_vip);
					console.log(this.vip)
				})
			},
			onSelectItem(index){
				if(this.tag == index)return;
				if(1 == index){
					// 真实环境替换掉
					window.open("http://doc.tkinte.com")
					return;
				}
				if(4== index && this.is_vip!=true){
					this.$message({
						type:"error",
						message:"暫無分銷資格，可聯繫工作人員開通"
					});
					return
				}
				this.tag = index;
				this.title = this.list[this.tag].title;
				
			},
			
			onHome(){
				this.$router.replace("/home");
			}
			
		}
		
	}
</script>

<style>
@import url("./Profile.css");
</style>