<template>
	<div class="user-list">
		
		<div class="user-main">
			
			<div v-if="is_mobile" class="user-info">
				
				<div class="user-info1">
					<el-image :src="user.avatar" style="border-radius: 50%;width: 80px;height: 80px;"></el-image>
					<div class="user-info3-t1" style="margin-left: 20px;">{{user.name}}</div>
				</div>
				
				<div class="user-info3" style="margin-left: 30px;margin-top: 10px;">
					<div class="user-info3-t2">
						<el-image :src="image.email" style="width: 20px;height: 20px;"></el-image>
						<div>帳號：{{user.phone}}</div>
					</div>
					<!-- <div class="user-info3-t2">
						<el-image :src="image.date" style="width: 20px;height: 20px;"></el-image>
						<div>註冊日期：{{user.time}}</div>
					</div> -->
				</div>
				
				<div class="user-info2" style="margin-top: 10px;">
					<!-- <div class="user-info2-t1">{{user.coin}}</div> -->
					<div class="user-info2-t2">帳戶金額:{{user.profit.amount}}</div>
					<div class="user-info2-t2">佣金金額:{{user.commission.commission}}</div>
					<div v-if= 'user.tg_id' class="user-info2-t2" style="display: flex;">tg:{{user.tg_name}}<div @click="Quxiaotg" style="background-color: #f52f54;border-radius: 5px;padding: 2px 5px;margin-left: 5px;font-size: 12px;">解除綁定</div></div>
				</div>
			</div>
			<div v-else class="user-info">
				<div class="user-info1">
					<el-image :src="user.avatar" style="border-radius: 50%;width: 80px;height: 80px;"></el-image>
					<div class="user-info3">
						<div class="user-info3-t1">{{user.name}}</div>
						<div class="user-info3-t2">
							<el-image :src="image.email" style="width: 20px;height: 20px;"></el-image>
							<div>帳號：{{user.phone}}</div>
						</div>
						<div class="user-info3-t2">
							<el-image :src="image.date" style="width: 20px;height: 20px;"></el-image>
							<div>註冊日期：{{user.time}}</div>
						</div>
					</div>
				</div>
				
				<div class="user-info2" style="margin-top: 30px;">
					<!-- <div class="user-info2-t1">{{user.coin}}</div> -->
					<div class="user-info2-t2">帳號金額:{{user.profit.amount}}</div>
					<div class="user-info2-t2">佣金金額:{{user.commission.commission}}</div>
					<div v-if= 'user.tg_id' class="user-info2-t2" style="display: flex;">telegram:{{user.tg_name}}<div @click="Quxiaotg" style="background-color: #f52f54;border-radius: 5px;padding: 2px 5px;margin-left: 5px;font-size: 12px;">解除綁定</div></div>
				</div>
			</div>
			
			<!-- <div> -->
				
			<div v-if="is_mobile" class ="user-info4">
				<div class="user-info4-1" style="margin-left: 0px;"  @click="onTransformCoin">
					<el-image :src="image.coin" style="width: 40px;height: 40px;"></el-image>
					<div style="margin-top: 10px;">佣金轉余額</div>
				</div>
				
				<div class="user-info4-2" style="margin-left: 10px;" @click="onDrawout">
					<el-image :src="image.coin" style="width: 40px;height: 40px;"></el-image>
					<div style="margin-top: 10px;">佣金提現</div>
				</div>
				
				<div class="user-info4-3" style="margin-left: 10px;" @click="onCharge">
					<el-image :src="image.coin" style="width: 40px;height: 40px;"></el-image>
					<div style="margin-top: 10px;">余额充值</div>
				</div>
			</div>
			
			<div v-else class ="user-info4">
				<div class="user-info4-1" style="margin-left: 0px;"  @click="onTransformCoin">
					<el-image :src="image.coin" style="width: 40px;height: 40px;"></el-image>
					<div style="margin-top: 10px;">佣金轉余額</div>
				</div>
				
				<div class="user-info4-2" style="margin-left: 30px;" @click="onDrawout">
					<el-image :src="image.coin" style="width: 40px;height: 40px;"></el-image>
					<div style="margin-top: 10px;">佣金提現</div>
				</div>
				
				<div class="user-info4-3" style="margin-left: 30px;" @click="onCharge">
					<el-image :src="image.coin" style="width: 40px;height: 40px;"></el-image>
					<div style="margin-top: 10px;">余额充值</div>
				</div>
			</div>
				
			<!-- <div :class="is_mobile?'user-info4':'user-info4'">
				
				<div class="user-info4-1" :style="{
					marginLeft:is_mobile?'0px':'0px'
				}" @click="onTransformCoin">
					<el-image :src="image.coin" style="width: 40px;height: 40px;"></el-image>
					<div style="margin-top: 10px;">佣金轉余額</div>
				</div>
				
				<div class="user-info4-2" :style="{
					marginLeft:is_mobile?'10px':'30px'
				}" @click="onDrawout">
					<el-image :src="image.coin" style="width: 40px;height: 40px;"></el-image>
					<div style="margin-top: 10px;">佣金提現</div>
				</div>
				
				<div class="user-info4-3" :style="{
					marginLeft:is_mobile?'10px':'30px'
				}" @click="onCharge">
					<el-image :src="image.coin" style="width: 40px;height: 40px;"></el-image>
					<div style="margin-top: 10px;">充值</div>
				</div>
			</div> -->
		</div>
		
		<div class="user-main">
			<div class="user-password">
				<el-image :src="image.password" style="width: 40px;height: 40px;"></el-image>
				<div style="margin-left: 10px;">修改密碼</div>
			</div>
			
			<div class="user-pass">
				<div class="user-pass1">
					<el-input
					  type="text"
					  placeholder="请输入密碼"
					  v-model="password.old_password"
					  maxlength="20"
					  show-word-limit
					  show-password
					>
					</el-input>
				</div>
				
				<div class="user-pass2">
					<el-input
					  type="password"
					  placeholder="新密碼"
					  v-model="password.new_password"
					  clearable
					  show-password>
					</el-input>
				</div>
				
				<div class="user-pass2">
					<el-input
					  type="text"
					  placeholder="重複新密碼"
					  v-model="password.new_password_second"
					  maxlength="20"
					  show-word-limit
					  clearable
					  show-password>
					</el-input>
				</div>
			</div>
			
			<div class="user-pass-save" @click="onSavePassword">
				保存
			</div>
		</div>
		
		<div class="user-main">
			<div class="user-password">
				<el-image :src="image.tool" style="width: 40px;height: 40px;"></el-image>
				<div style="margin-left: 10px;">我的工具</div>
			</div>
			<UserToolView></UserToolView>
		</div>
		
		<div class="user-main">
			<div class="user-password">
				<el-image :src="image.tool" style="width: 40px;height: 40px;"></el-image>
				<div style="margin-left: 10px;">我的提現</div>
			</div>
			<OutCashView></OutCashView>
		</div>
		
		
		<div class="user-bottom"></div>
		
		<el-dialog title="佣金转余额" v-model="showDialog" center align-center class="user-dialog">
		    <div class="user-dialog-transform">
				<div class="user-dialog-transform-t1">
					<span style="font-weight: bold;">注意：</span>
					<span>划转后的金額进入余額，不可提現，仅用於站內消費。<span style="color:red;font-weight: bold;">可划转：{{user.commission.commission}} usdt</span></span>
				</div>
			    <span style="margin-top: 16px;">划转余額</span>
				<div class="user-dialog-input">
					<el-input
					  type="text"
					  placeholder="余額"
					  v-model="input1"
					  clearable>
					</el-input>
				</div>
				<div class="user-dialog-ok">
					<div class="user-dialog-ok1" @click="onStartTransform">
						確定
					</div>
				</div>
		    </div>
		</el-dialog>
		
		
		<el-dialog title="佣金提現" width="70%" v-model="showDialog2" center align-center class="user-dialog">
		    <div class="user-dialog-transform">
			    <span>提现金额:(满{{withdraw_price}}U可提现)</span>
				<div class="user-dialog-input">
					<el-input
					  type="text"
					  placeholder="金額"
					  v-model="draw_form.money"
					  maxlength="20"
					  clearable>
					</el-input>
				</div>
				<span>提現帳戶</span>
				<div class="user-dialog-input">
					<el-input
					  type="text"
					  placeholder="帳戶"
					  v-model="draw_form.address"
					  maxlength="50"
					  clearable>
					</el-input>
				</div>
				<span style="margin-top: 20px;">提現方式</span>
				<div class="user-dialog-input">
					<el-select v-model="draw_form.platform" placeholder="請選擇提現方式">
					    <el-option label="USDT-TRC20" value="USDT-TRC20"></el-option>
				<!-- 	    <el-option label="支付宝" value="2"></el-option>
						<el-option label="微信" value="3"></el-option> -->
					</el-select>
				</div>
				<div class="user-dialog-ok">
					<div class="user-dialog-ok1" @click="onStartDraw">
						確定
					</div>
				</div>
		    </div>
		</el-dialog>
		
		<el-dialog title="帳戶充值" width="70%" v-model="showDialog3" center align-center class="user-dialog">
		    <div class="user-dialog-transform">
				<span style="margin-top: 20px;">充值金額</span>
				<div class="user-dialog-input">
					<el-input
					  type="text"
					  placeholder="请输入金額"
					  v-model="re_form.price">
					</el-input>
				</div>
				<el-form-item label="充值方式">
				  <div class="shop-dialog-option-re">
					  <div v-for="(item,index) in optionsList" :key="index">
						  <div :class="re_form.platform_id == item.id ? 'shop-dialog-option-item0-re':'shop-dialog-option-item1-re'"
						  @click="onSelectWayItem(item.id)">
							  {{item.platform}}
						  </div>
					  </div>
				  </div>
				</el-form-item>
				<div class="user-dialog-ok">
					<div class="user-dialog-ok1" @click="onStartCharge">
						確定
					</div>
				</div>
		    </div>
		</el-dialog>
		
	</div>
</template>

<script>
	import {CancelTg,UserInfo,CommissionToProfit,UpdatePassword,Withdraw,Payments,RechargePrice} from "../api/index";
	
	import UserToolView from "./UserTool.vue";
	import OutCashView from "./OutCash.vue";
	
	export default {
		
		components:{
			UserToolView,
			OutCashView
		},
		
		data(){
			return{
				input1:0,
				user:{
					name:"用户昵称",
					phone:"xxxxx@gmail.com",
					time:"2023-01-02 10:00:00",
					tg_id:'',
					tg_name:'',
					profit:{ 
							amount: "0.00",
							finish_amount: "0.00",
							total_amount: "0.00"}, //余额
					commission:{
							commission: "0.00",
							to_price:"0.00",
							total_commission:"0.00",
							withdrawal_commission:"0.00"
					}, //佣金
					password:"xxxxx",
					avatar:require("../assets/img/icon.png")
				},
				
				image:{
					share:require("../assets/img/iconfont/share.png"),
					coin:require("../assets/img/iconfont/commission.png"),
					email:require("../assets/img/iconfont/email.png"),
					date:require("../assets/img/iconfont/date.png"),
					password:require("../assets/img/iconfont/lock.png"),
					tool:require("../assets/img/iconfont/tool.png"),
				},
				password:{
					old_password:"",
					new_password:"",
					new_password_second:"",
				},
				optionsList:[],
				showDialog:false,
				showDialog2:false,
				showDialog3:false,
				draw_form:{
					address:'',
					platform:"",
					money:0
				},
				is_mobile:false,
				re_form:{
					"platform_id":1,
					"price":0
				}
				
			}
		},
		
		created() {
			this.is_mobile = this.isMobile();
			this.user_info()
		},
		
		methods:{
			
			Quxiaotg(){
				CancelTg().then(res=>{
					if(res.code ==0){
						this.$message({
							type:"success",
							message:"取消成功"
						});
						location.reload();
					}
				})
				
			},
			isMobile() {
			    let userAgentInfo = navigator.userAgent;
			    let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
			    let getArr = Agents.filter(i => userAgentInfo.includes(i));
			    return getArr.length ? true : false;
			},
			//选择充值方式
			onSelectWayItem(index){
			    this.re_form.platform_id= index;
			},
			// 转余额
			onTransformCoin(){
				this.input1 = 0;
				this.showDialog = true;
			},
			//获取用户信息
			user_info(){
				UserInfo().then(res => {
					console.log(res)
					localStorage.setItem("commission", res.data.commission);
					localStorage.setItem("profit", res.data.profit);
					this.user.commission = res.data.commission
					this.user.profit = res.data.profit
					this.user.name = res.data.phone
					this.user.phone = res.data.phone
					this.user.tg_id = res.data.tg_id
					this.user.tg_name = res.data.tg_name
					this.user.time = res.data.create_time
				})
			},
			// 开始划转
			onStartTransform(){
				if(!this.input1){
					this.$message({
						type:"error",
						message:"请输入金额"
					});
					return;
				}

				if(parseFloat(this.input1) > parseFloat(this.user.commission.commission)){
					this.$message({
						type:"error",
						message:"佣金不足"
					});
					return;
				}
				
				this.showDialog = false;
				//划转请求
				CommissionToProfit({"money":this.input1}).then(res=>{
					if(res.code!=0){
						this.$message({
							type:"error",
							message:"划转失敗"
						});
					}else{
						this.user_info()
						this.$message({
							type:"success",
							message:"划转成功"
						});
					}
				})
				
				// this.input1 = 0;
				// this.user.commission -= this.input1;
				// this.user.coin += this.input1;
				
			},
			
			// 提现
			onDrawout(){
				this.input1 = "";
				this.input2 = "1";
				this.showDialog2 = true;
				this.draw_form.money = this.user.commission.commission
				this.withdraw_price = localStorage.getItem("withdraw_price")
			},
			
			onStartDraw(){
				if(!this.draw_form.money){
					this.$message({
						type:"error",
						message:"请输入提现金额"
					});
					return;
				}
				if(!this.draw_form.address){
					this.$message({
						type:"error",
						message:"请输入提现账号"
					});
					return;
				}
				console.log(this.draw_form)
				Withdraw(this.draw_form).then(res=>{
					if(res.code !=0){
						this.$message({
							type:"error",
							message:res.msg
						});
					}else{
						this.$message({
							type:"success",
							message:"提現申請成功，請等待工作人員打款"
						});
					}
				})
				
				this.showDialog2 = false;
				// this.input1 和 this.input2申请提现
				
				
			},
			
			// 充值
			onCharge(){
				this.input1 = "";
				this.input2 = "";
				this.showDialog3 = true;
				Payments().then(res=>{
					console.log(res)
					this.optionsList = res.data
				})
			},

			onStartCharge(){
				if(!this.re_form.price){
					this.$message({
						type:"error",
						message:"请输入金额"
					});
					return;
				}
				
				// this.showDialog3 = false;
				RechargePrice(this.re_form).then(res=>{
					if (res.code == 0) {
						window.location.href = res.data
					} else {
						this.$message.error('充值失敗，請聯繫客服人員充值');
					}
				})
				
			},
			//
			onSavePassword(){
				if(!this.password.old_password || !this.password.new_password_second){
					this.$message({
						type:"error",
						message:"新密碼和舊密碼重複"
					});
				    return;
				}
				
				if(this.password.new_password != this.password.new_password_second){
					this.$message({
						type:"error",
						message:"兩次密碼不一致"
					});
					return;
				}
				console.log(this.password)
				UpdatePassword(this.password).then(res=>{
					if(res.code!=0){
						this.$message({
							type:"error",
							message:res.msg
						});
					}else{
						this.$message({
							type:"success",
							message:"修改成功"
						});
						localStorage.setItem("token","");
						this.is_login = false;
						location.reload();
					}
				})
				
			}
			
		}
		
	}
</script>

<style>
@import url("./User.css");
</style>