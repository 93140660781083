<template>
	<div class="invite-item">
		
		<div class="invite-menu">
			<el-table
			    :data="invites"
				:show-header="true"
				class="invite-table">
			    <el-table-column
			      fixed
			      prop="create_time"
			      label="時間"
				  align="center">
			    </el-table-column>
				
			    <el-table-column
			      prop="commission_num"
			      label="佣金"
				  align="center">
			    </el-table-column>
				<el-table-column
				  prop="before_commission"
				  label="變動之前"
				  align="center">
				</el-table-column>
				<el-table-column
				  prop="after_commission"
				  label="變動之後"
				  align="center">
				</el-table-column>
				
			    <el-table-column label="狀態" align="center">
			         <template #default="scope">
			    		
			    		<el-tag v-if="scope.row.record_category ===0" type="danger" >下級收益增加</el-tag>
			    		<el-tag v-if="scope.row.record_category ===1" type="info" >划轉減少</el-tag>
			    		<el-tag v-if="scope.row.record_category ===2" type="success" >提現減少</el-tag>
			    		<el-tag v-if="scope.row.record_category ===3" type="danger" >提現拒絕</el-tag>
			         </template>
			     </el-table-column>
			</el-table>
		</div>
		
		<div class="invite-pagination">
			<el-pagination
				background
				layout="total, prev, pager, next"
				:current-page="form.p"
				:page-size="form.num_of_page"
				:total="form.total"
				@current-change="handlePageChange"
			></el-pagination>
		</div>
	</div>
</template>

<script>
	import {CommissionRecord} from "../api/index";
	export default {
		
		data(){
			return{
				invites: [
				],
				
				form:{
					p:1,
					num_of_page:5,
					total:5,
				}
			}
		},
		created() {
			this.get_commission_records()
		},
		methods:{
			
			get_commission_records(){
				CommissionRecord(this.form).then(res=>{
					this.invites = res.data.results
					this.form.total = res.data.total
				})
			},
			handlePageChange(val) {
			    this.form.p = val;
				this.get_commission_records()
			    
			},
			
			// 编辑操作
			handleEdit(index,data) {
			   this.$copyText(data.url).then(()=>{
				   this.$message({
					   type:"success",
					   message:"複製成功"
				   });
			   });
			},
			
			// 新建邀请码
			onNewCode(){
				
			}
			
		}
		
		
	}
</script>

<style>
	
	.invite-item{
		display: flex;
		align-items:center;
		flex-direction: column;
		width: 100%;
	}
	
	.invite-menu{
		width: 100%;
		margin-top: 20px;
	}
	
	.invite-table{
		width: 100%;
		margin-top: 0px;
	}
	
	.invite-pagination{
		width: 100%;
		margin-top: 30px;
		
		display:flex;
		align-items: center;
		justify-content: center;
	}
	
	
</style>