<template>
	<div class="guide-list">
		<div v-for="(item,index) in list" :key="index">
			<div :class="is_mobile?'guide-list-item0':'guide-list-item1'" >
				
				<div v-if="is_mobile" style="font-size: 26px;color: #000;font-weight: 600;align-items: center;width: 100%;display: flex;
		flex-direction:column;">
					{{item.name}} 
					<div v-if="item.window_url" style="font-size:16px;color: #34b785;font-weight: 400;margin-left: ;margin-right: 0;" @click="onItem(item.window_url)">
						【window下載】
					</div>
					<div v-if="item.mac_url" style="font-size: 16px;color: #34b785;font-weight: 400;margin-left: 0;margin-right: 0;" @click="onItem(item.mac_url)">
						【苹果mac下載】
					</div>
				</div>
				<div v-else style="font-size: 26px;color: #000;font-weight: 600;margin-left:100px;">
					{{item.name}}
					<div style="display: flex;margin-top: 10px;" >
						<div v-if="item.window_url" style="font-size:16px;color: #34b785;font-weight: 400;" @click="onItem(item.window_url)">
							【window下載】
						</div>
						<div v-if="item.mac_url" style="font-size: 16px;color: #34b785;font-weight: 400;" @click="onItem(item.mac_url)">
							【苹果mac下載】
						</div>
					</div>
					
				</div>
				
				
				
				<div v-if="is_mobile" style="font-size: 18px;color: #000;font-weight: 600;display: flex;padding-left: 20%;"  @click="onItem(item.video_url)">
					<el-image style="width: 20%;height: 100%;" :src="image.v_icon"></el-image>
					<div style="padding:5px;">观看教程</div>
				</div>
				<div v-else style="font-size: 18px;color: #000;font-weight: 600;margin-right: 100px;" @click="onItem(item.video_url)">
					观看教程
					<iframe width="200" height="150" :src="item.video_url"  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
					
				</div>
			</div>
		</div>
		
		<div style="width: 100%;height: 30px;">
			
		</div>
		
	</div>
</template>

<script>
	import {ToolTuTorial} from "../api/index";
	export default {
		
		data(){
			return{
				list:[
					// {title:"视频教程"},
					// {title:"视频教程"},
					// {title:"视频教程"},
					// {title:"视频教程"},
					// {title:"视频教程"},
					// {title:"视频教程"},
					// {title:"视频教程"},
					// {title:"视频教程"},
					// {title:"视频教程"},
					// {title:"视频教程"}
				],
				image:{
					v_icon:require("../assets/img/V1.png"),
					
				},
				is_mobile:false
			}
		},
		
		created(){
			this.is_mobile = this.isMobile();
			this.get_data()
		},
		
		methods:{
			
			isMobile() {
			    let userAgentInfo = navigator.userAgent;
			    let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
			    let getArr = Agents.filter(i => userAgentInfo.includes(i));
			    return getArr.length ? true : false;
			},
			get_data(){
				ToolTuTorial().then(res=>{
					this.list = res.data.results
				})
			},
			

			onItem(url){
				window.open(url);
			}
			
		}
		
		
	}
</script>

<style>
	
	.guide-list{
		width: 100%;
		margin: 0px 30px 30px 30px;
	}
	
	.guide-list-item0{
		margin-bottom: 1.875rem;
		background-color: #fff;
		transition: all .5s ease-in-out;
		position: relative;
		border: 0 solid transparent;
		border-radius: 1.25rem;
		box-shadow: 0 0.3125rem 0.3125rem 0 rgb(82 63 105 / 5%);
		
		min-width: 200px;
		height: 200px;
		margin-top: 30px;
		
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
		cursor: pointer;
	}
	
	.guide-list-item1{
		margin-bottom: 1.875rem;
		background-color: #fff;
		transition: all .5s ease-in-out;
		position: relative;
		border: 0 solid transparent;
		border-radius: 1.25rem;
		box-shadow: 0 0.3125rem 0.3125rem 0 rgb(82 63 105 / 5%);
		
		min-width: 200px;
		height: 200px;
		margin-top: 30px;
		
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		
		cursor: pointer;
	}
	
</style>