<template>
	<div class="order-list">
		<div class="order-main">
			
			<!-- <div class="order-top">
				我的提现
			</div>
			 -->
			<div class="order-menu">
				<el-table
				    :data="list"
					:show-header="true"
					class="order-table">
				    <el-table-column
				      fixed
				      prop="commission_num"
				      label="金額"
					  align="center"
					  >
				    </el-table-column>
					
				    <el-table-column
				      prop="u_address"
				      label="提現地址"
					  align="center">
				    </el-table-column>		
						
					<el-table-column
					  prop="u_platform"
					  label="提現平台"
					  align="center">
					</el-table-column>	
				    <el-table-column
				      prop="create_time"
				      label="提現時間"
					  align="center">
				    </el-table-column>
					<el-table-column label="狀態" align="center">
					     <template #default="scope">
							 <el-tag v-if="scope.row.record_category ===0" type="info" >提現中</el-tag>
							<el-tag v-if="scope.row.record_category ===1" type="success" >提現完成</el-tag>
							<el-tag v-if="scope.row.record_category ===2" type="danger" >提現失敗</el-tag>
					     </template>
					 </el-table-column>
					 <el-table-column
					   prop="remark"
					   label="备注"
					   align="center">
					 </el-table-column>
				</el-table>
			</div>
			
			<div class="invite-pagination">
			    <el-pagination
			        background
			        layout="total, prev, pager, next"
			        :current-page="form.p"
			        :page-size="form.num_of_page"
			        :total="form.total"
			        @current-change="handlePageChange"
			    ></el-pagination>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	import {OutCashRecords} from "../api/index";
	export default {
		data(){
			return{
				
				list: [
				],
				
				form:{
					p:1,
					num_of_page:10,
					total:0,
				},
				
				is_mobile:false,
				
				pagination_layout:"pager"
			}
		},
		
		created() {
			this.is_mobile = this.isMobile();
			if(!this.is_mobile){
				this.pagination_layout = "prev,pager,next";
			}
			this.recharge_price_orders()
		},
		
		methods:{
			recharge_price_orders(){
				OutCashRecords(this.form).then(res=>{
					this.list = res.data.results
					this.form.total = res.data.total
				})
			},
			isMobile() {
			    let userAgentInfo = navigator.userAgent;
			    let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
			    let getArr = Agents.filter(i => userAgentInfo.includes(i));
			    return getArr.length ? true : false;
			},
			
			onItem(item){
				console.log(item);
			},
			
			handlePageChange(val) {
			    this.form.p = val;
				this.recharge_price_orders()
			    
			}
			
		}
		
	}
</script>

<style>
@import url("./Order.css");
</style>