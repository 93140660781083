<template>
	<div class="">
		
		<div class="shop-list">
			<div v-for="(item,index) in list" :key="index">
				<div class="shop-list-item">
					
					<div style="font-size: 20px;color: #000;font-weight: 500;">
						{{(index+1)}}.{{item.name}}
					</div>
					
					<div class="shop-item-price">
						<div style="font-size: 40px;font-weight: bold;color: #2fc898;line-height: 40px;">
							<!-- {{item.price}} -->
							{{item.month}}
						</div>
						<div style="font-weight: bold;color: #908fa4;margin-left: 10px;font-size: 16px;line-height: 16px;margin-top: 20px;">
							USDT/每月
						</div>
					</div>
					
					
					<div class="shop-item-info">
						<div>{{item.remark}}</div>
						<!-- <div style="margin-top: 10px;">2、eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee</div>
					    <div style="margin-top: 10px;">3、啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊eeeeeeeeeeee啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee</div> -->
					</div>
					
					<div class="shop-list-item-buy" @click="onItem(item.id,item.price_packages)">
						立即订阅
					</div>
					
				</div>
			</div>
		</div>
		
		<el-dialog width="70%" v-model="showDialog" align-center center class="shop-dialog">
			<el-form
			    label-position="right"
			    label-width="100px"
			    :model="formItem"
				style="min-width: 400px;">
				<el-form-item label="账户余额:">
				  <div style="font-weight: bold;font-size: 18px;color: #2fc898;">{{price}} usdt</div>
				</el-form-item>
			    <el-form-item label="TK充值账号">
			      <el-input v-model="formItem.account" placeholder="请输入账号" />
			    </el-form-item>
			    <el-form-item label="充值价格">
			      <div class="shop-dialog-option">
					  <div v-for="(item,index) in optionsList" :key="index">
						  <div :class="formItem.tool_price_id == item.id  ? 'shop-dialog-option-item0':'shop-dialog-option-item1'"
						  @click="onSelectWayItem(item.id,item.twitter_tool)">
							  {{item.remark}}/{{item.price}}U
						  </div>
					  </div>
				  </div>
			    </el-form-item>
			  </el-form>
			  
			  <div class="shop-dialog-ok">
			  	<div class="shop-dialog-ok1" @click="onCharge">
			  		立即订阅
			  	</div>
			  </div>
		</el-dialog>
		
		<el-dialog v-model="showDialog2" align-center center class="shop-dialog">
			
			<div style="display: flex;flex-direction: column;">
				<el-form-item label="账户余额:">
				  <div style="font-weight: bold;font-size: 18px;color: #2fc898;">{{price}} usdt</div>
				</el-form-item>
				<span>TK充值账号</span>
				<el-input
				  type="text"
				  style="margin-top: 4px;"
				  placeholder="请输入账户"
				  v-model="formItem.account"
				  maxlength="20"
				  clearable>
				</el-input>
				
				<span style="margin-top: 20px;">充值方式</span>
				<div class="shop-dialog-option">
					<div v-for="(item,index) in optionsList" :key="index">
				        <div :class="formItem.tool_price_id == item.id ? 'shop-dialog-option-item0':'shop-dialog-option-item1'"
				        @click="onSelectWayItem(item.id,item.twitter_tool)">
				 	       {{item.remark}}/{{item.price}}U
				        </div>
					</div>
				</div>
				<div class="shop-dialog-ok">
					<div class="shop-dialog-ok1" @click="onCharge">
						立即订阅
					</div>
				</div>
			</div>
		</el-dialog>
		
	</div>
</template>

<script>
	import {Tools,OpenToolVip} from "../api/index";
	export default {
		
		data(){
			return{
				
				list:[
					// {title:"功能一",price:2000,tag:"USDT/每月"},
					// {title:"功能二",price:2000,tag:"USDT/每月"},
					// {title:"功能三",price:2000,tag:"USDT/每月"},
					// {title:"功能四",price:2000,tag:"USDT/每月"},
					// {title:"功能五",price:2000,tag:"USDT/每月"},
					// {title:"功能六",price:2000,tag:"USDT/每月"},
					// {title:"功能七",price:2000,tag:"USDT/每月"},
					// {title:"功能八",price:2000,tag:"USDT/每月"},
					// {title:"功能九",price:2000,tag:"USDT/每月"},
					// {title:"功能十",price:2000,tag:"USDT/每月"}
				],
				shopIndex:0,
				price:100,
				showDialog:false,
				showDialog2:false,
				optionsList:[
				    {title:"年卡",num:692,id:1},	
					{title:"半年卡",num:434,id:2},	
					{title:"季卡",num:235,id:3},	
					{title:"月卡",num:90,id:4},	
				],
				formItem:{
					account:"",
					tool_price_id:'',
					tool_id:''
				},
				
				is_mobile:false,
				
			}
		},
		
		created() {
			this.is_mobile = this.isMobile();
			this.get_tools()
	
		},
		
		methods:{
			get_tools(){
				Tools().then(res=>{
					this.list = res.data
				})
			},
			isMobile() {
			    let userAgentInfo = navigator.userAgent;
			    let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
			    let getArr = Agents.filter(i => userAgentInfo.includes(i));
			    return getArr.length ? true : false;
			},
			
			onItem(index,data){
				console.log(data)
				this.optionsList = data
				this.shopIndex = index;
				this.price = localStorage.getItem("amount");
				this.formItem.account = localStorage.getItem("tt_username");
				if(this.is_mobile){
					this.showDialog2 = true;
				}else{
					this.showDialog = true;
				}
			},
			
			onSelectWayItem(index,tool_id){
			    this.formItem.tool_price_id = index;
				this.formItem.tool_id = tool_id
			},
			
			onCharge(){
				console.log(this.formItem)
				if(!this.formItem.account){
					this.$message({
						type:"error",
						message:"请输入账号"
					});
					return;
				}
				OpenToolVip(this.formItem).then(res=>{
					console.log(res)
					if(res.code !=0){
						this.$message({
							type:"error",
							message:res.msg
						});
					}else{
						this.$message({
							type:"success",
							message:"开通成功"
						});
					}
				})
				
				this.showDialog2 = false;
				this.showDialog = false;
				
				console.log(this.list[this.shopIndex]);
				
			},
			
		}
		
	}
</script>

<style>
@import url("./Shop.css");
</style>